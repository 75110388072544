import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"

class About extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    // TODO: move the content to markdown
    return (
      <Layout location={this.props.location} title={siteTitle}>
        <h1>About</h1>
        <section>
          I'm Dano, some bloke in his twenties who likes to talk and code
          (usually not at the same time, although interviews like to make
          candidates do that). This website is purely a blog for now. I hope you
          enjoy reading my crap and if you have a way of contacting me then feel
          free to talk to me about my content, and please help me correct my
          typos!
        </section>
        <section>
          <h2>Licenses</h2>
          <p>
            <b>favicon.png</b>
            <br />
            Author:{" "}
            <a href="https://commons.wikimedia.org/wiki/File:Letter_d.svg">
              Bequw
            </a>
            <br />
            License:{" "}
            <a href="https://creativecommons.org/licenses/by-sa/3.0/deed.en">
              Creative Commons Attribution-Share Alike 3.0 Unported
            </a>
          </p>
        </section>
      </Layout>
    )
  }
}

export default About

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
